@use "sass:meta" as ---3m2zn08l3uw;.market-index {
  h1 {
    @include breakpoint(medium down) {
      margin-top: 1rem;
      margin-bottom: 2rem;
    }

    @include breakpoint(large) {
      margin-bottom: 2rem;
    }

    small {
      font-size: 60%;
      margin-left: 1rem;
    }
  }

  &__rubrica_list {
    .item {
      height: 80px;
      background-size: 80px;
      background-repeat: no-repeat;
      background-position-x: 98%;
      background-color: $alice-blue;
      border-radius: 3px;
      padding: 10px 0 10px 10px;
      transition: .2s;

      &:hover {
        background-color: #feca2e;
        cursor: pointer;
      }

      .text {
        width: 55%;
        line-height: 1.3;
        color: #000;
        font-weight: $global-weight-bolder;
      }
    }
  }

  &__subscribe {
    margin-top: 2rem;
    margin-bottom: 2rem;
    align-items: center;

    .subscribe-tizer {
      border-radius: 3px;
      margin-top: 0;
      margin-bottom: 0;

      @include breakpoint(small only) {
        margin-top: 2rem;
      }
    }

    .market-count {
      background-color: #5073af;
      border-radius: $global-radius;
      padding: 1rem;
      color: #fff;
      font-size: 18px;
      font-weight: $global-weight-bold;

      @include breakpoint(small only) {
        font-size: rem-calc(26);
        text-align: center;
      }

      @include breakpoint(large) {
        text-align: end;
      }

      &_text {
        font-weight: normal;
        font-size: rem-calc(14);

        @include breakpoint(small only) {
          font-size: 16px;
          text-align: center;
        }
      }
    }
  }

  &__sellers {
    .item {
      background-color: #fff;
      border-radius: 10px;
      box-shadow: 0 1px 4px #bfbfbf;
      display: flex;
      justify-content: center;
      margin: 10px 0;
      padding: 15px;
      position: relative;
      height: 130px;
      transition: .2s;

      @include breakpoint(medium down) {
        height: 110px;
      }

      &:hover {
        box-shadow: 0 6px 10px 2px #bfbfbf;
        cursor: pointer;
      }

      img {
        object-fit: contain;
      }
    }
  }

  &__popular,
  &__publications,
  &__sellers {
    padding-top: 2rem;
    padding-bottom: 2rem;

    h2 {
      font-weight: $global-weight-bold;

      &::after {
        content: '';
        display: block;
        background-color: $alice-blue;
        height: 4px;
        width: 3vw;
        margin-bottom: 1rem;

        @include breakpoint(small only) {
          width: 10vw;
        }
      }
    }
  }
}

;@include ---3m2zn08l3uw.load-css("sass-embedded-legacy-load-done:3740");