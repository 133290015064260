@use "sass:meta" as ---eyygoxs6v1;@use 'sass:math';

.fotorama {
  @include breakpoint(small only) {
    border: 1px solid $medium-gray;
    border-radius: $global-radius;
    padding: .5rem;
  }

  &__nav-wrap {
    padding-top: .5rem;
  }
}

.offer-detail {
  p {
    font-size: rem-calc(14);
    margin: 0 0 math.div($global-margin, 2);
  }

  &__image {
    &:has(.placeholder) {
      display: flex;
      justify-content: center;
      align-items: center;
      aspect-ratio: 4/3;
    }
  }

  .placeholder {
    @include breakpoint(small only) {
      padding-top: 45%;
    }
  }

  &__thumb {
    @include obj-thumb(250px, 250px, small);

    @include breakpoint(medium) {
      min-height: 250px;
      border: 1px solid $medium-gray;
      border-radius: $global-radius;
    }

    img {
      max-width: 90% !important;
    }
  }

  &__title {
    font-weight: $global-weight-bolder !important;
    line-height: 1.2 !important;
    margin: 0 0 15px !important;
  }

  &__action {
    .on-site {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: .3rem;

      &::after {
        content: '';
        display: inline-block;
        background-image: url('../../../styles/common/svg/on-site.svg');
        background-size: contain;
        background-repeat: no-repeat;
        width: 12px;
        height: 12px;
        opacity: .5;
      }
    }
  }

  &__info {
    @include breakpoint(small only) {
      margin-top: $global-margin;
    }

    @include breakpoint(large) {
      position: relative;
      min-height: 10rem;
    }

    &-data {
      a {
        font-weight: $global-weight-bolder;
      }

      &__cost {
        color: $dark-gray;
        font-size: rem-calc(18) !important;
        margin-bottom: $global-margin;
      }

      &__availability {
        font-size: $global-font-size !important;
        margin: -1px 0 13px !important;
      }

      &__price {
        color: get-color(san-marino);
        font-size: rem-calc(24);
        font-weight: $global-weight-bolder;
      }

      p {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        color: $dark-gray;
      }

      strong {
        font-weight: $global-weight-bolder;
        color: $light-black;
      }
    }
  }

  &__params {
    th {
      font-weight: $global-weight-bold;
      text-align: right;
    }

    td {
      word-break: break-all;
    }
  }

  &__other {
    color: $dark-gray;
    font-size: rem-calc(13);
    margin-top: $global-margin;
  }
}

.offer-card {
  &__stock {
    .availible-info {
      font-weight: $global-weight-bold;
      font-size: 1rem;
    }

    &-warehouse {
      margin-bottom: 1.5rem;

      @include breakpoint(large) {
        width: 80%;
      }

      .row {
        padding: .2rem;

        &:nth-child(odd) {
          background-color: #fafafa;
        }
      }
    }
  }

  &__price {
    color: #000;
    font-size: rem-calc(26);
    font-weight: $global-weight-bold;
  }

  &__info {
    margin-bottom: $global-margin;

    h2 {
      font-size: rem-calc(20);
      font-weight: 700;
      margin-bottom: 1.5rem;
      position: relative;

      @include breakpoint(small only) {
        background: none;
        padding: 0;
        border: none;
      }

      &::after {
        display: block;
        margin-top: 5px;
        background-color: #e6e6e6;
        content: '';
        height: 6px;
        position: absolute;
        width: 50px;
      }
    }

    .features {
      font-size: rem-calc(15);
      margin-bottom: $global-margin;

      .property {
        align-items: flex-end;

        .column {
          margin: 4px 0;
          line-height: 1.3;

          .item {
            position: relative;
            display: inline-flex;
            flex-grow: 1;
            align-self: flex-end;
            width: 100%;

            &::before {
              content: '';
              position: absolute;
              left: 0;
              bottom: 6px;
              width: 100%;
              border-bottom: 1px dotted #e4e4e4;
            }

            .title {
              position: relative;
              padding-right: 4px;
              background: #fff;
              color: #666;
            }
          }
        }
      }
    }
  }
}

;@include ---eyygoxs6v1.load-css("sass-embedded-legacy-load-done:3785");