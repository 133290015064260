@use "sass:meta" as ---eyygoxs6v1;.market-rubrics {
  &__items {
    margin-top: 1rem;
    margin-bottom: 2rem;

    a > div {
      position: relative;
      background-color: $alice-blue;
      border-radius: 10px;
      transition: .2s;
      overflow: hidden;
      aspect-ratio: 1;

      &:hover {
        background-color: #feca2e;
        cursor: pointer;
      }

      &.bg-img {
        background-size: 55%;
        background-repeat: no-repeat;
        background-position: 100% 100%;

        @include breakpoint(medium down) {
          background-size: 50%;
        }

        .title {
          font-size: rem-calc(15px);
          font-weight: $global-weight-bolder;
          line-height: 1.2;
          color: #000;
          margin: 1rem;

          @include breakpoint(small only) {
            font-size: rem-calc(14px);
            hyphens: auto;
          }
        }

        .counter {
          color: $dark-gray;
          font-size: rem-calc(12px);
          margin: 1rem;
        }
      }
    }
  }

  &__company {
    margin: 3rem 0 2rem;

    h2 {
      font-weight: $global-weight-bold;

      @include breakpoint(small only) {
        background: none;
        border: none;
        padding: 0;
        font-size: rem-calc(22);
        line-height: 1.2;
      }

      &::after {
        content: '';
        display: block;
        background-color: $alice-blue;
        height: 4px;
        width: 3vw;
        margin-bottom: 1rem;

        @include breakpoint(small only) {
          width: 10vw;
        }
      }
    }

    .item {
      position: relative;
      background-color: #fff;
      box-shadow: 0 1px 4px #bfbfbf;
      border-radius: 10px;
      display: flex;
      justify-content: center;
      padding: 15px;
      transition: .2s;
      margin: 10px 0;

      &:hover {
        box-shadow: 0 6px 10px 2px #bfbfbf;
      }
    }
  }

  &__hotprice {
    margin-bottom: 2rem;
    margin-top: .5rem;

    .title {
      font-size: rem-calc(18);
      font-weight: $global-weight-bold;
      margin-bottom: .5rem;

      span {
        font-weight: $global-weight-normal;
      }
    }

    .item {
      margin-bottom: 1.5rem;

      img {
        border-radius: $global-radius;
        width: 160px;
      }

      div {
        display: -webkit-box; /* stylelint-disable-line */
        font-size: rem-calc(15);
        font-weight: $global-weight-bolder;
        margin-bottom: .5rem;
        overflow: hidden;
        text-overflow: ellipsis;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
      }
    }

    .more {
      font-weight: $global-weight-bolder;
      margin-top: 1rem;
    }
  }

  &__info {
    @include show-for(medium);

    @media print {
      display: none !important;
    }

    color: $light-black;
    margin-top: 1rem;

    h2 {
      font-weight: $global-weight-bold;
    }
  }
}

;@include ---eyygoxs6v1.load-css("sass-embedded-legacy-load-done:3767");