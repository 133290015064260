@use "sass:meta" as ---qyzyywc1vw;.offer-block {
  cursor: pointer;
  padding: 1rem;

  @include breakpoint(medium) {
    border: 1px solid rgb(225 225 225 / 50%);
    border-radius: $global-radius;

    &:hover {
      box-shadow: 0 2px 10px rgb(0 0 0 / 15%);
    }
  }

  &-wrap {
    @include small-obj;

    @include breakpoint(medium) {
      padding: 3px;
    }

    padding: 0;
    margin: 0;
  }

  &__img {
    height: 150px;
    width: auto;
    margin-bottom: $global-margin;
    text-align: center;
    overflow: hidden;

    &-placeholder {
      padding-top: 60% !important;

      @include breakpoint(small only) {
        padding-top: 50% !important;
      }
    }
  }

  &-info {
    &__title {
      font-weight: $global-weight-bold;

      @include breakpoint(small only) {
        margin-bottom: .5rem;
      }

      @include breakpoint(medium) {
        height: 30px;
        display: -webkit-box; /* stylelint-disable-line */
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical; /* stylelint-disable-line property-no-vendor-prefix */
        overflow: hidden;
        text-overflow: ellipsis;
        font-size: rem-calc(13);
        line-height: 15px;
        margin-bottom: .4rem;
        font-weight: $global-weight-normal;
      }

      a {
        color: #0a0a0a;

        &:hover,
        &:visited {
          color: #0a0a0a;
        }
      }
    }

    &__price {
      color: #cb0209;
      font-size: rem-calc(15);
    }

    &__company {
      white-space: nowrap;
      overflow: hidden;
      margin-top: .3rem;

      a {
        display: inline-block;
        overflow: hidden;
        vertical-align: bottom;
        text-overflow: ellipsis;
        color: $dark-gray;
        font-size: rem-calc(11);
        max-width: 200px;

        @include breakpoint(medium) {
          max-width: 175px;
        }

        @include breakpoint(xlarge) {
          max-width: 146px;
        }

        &:hover,
        &:visited {
          color: $dark-gray;
        }
      }
    }
  }
}

;@include ---qyzyywc1vw.load-css("sass-embedded-legacy-load-done:3780");