@use "sass:meta" as ---eyygoxs6v1;@mixin small-h($bg-color: #f5f5ea) {
  @include breakpoint(small only) {
    display: block;
    padding: 10px 15px;
    background-color: $bg-color;
    border-top: 1px solid $light-gray;
    border-bottom: 1px solid $light-gray;
  }
}

@mixin small-l() {
  @include breakpoint(small only) {
    margin: 0;

    & > li,
    & > div {
      display: block;
      height: auto;
      margin: 0;
      padding: $global-padding 0;
      box-sizing: border-box;
      border-bottom: 1px solid $medium-gray;
      list-style: none;
      line-height: initial;

      &.selected {
        font-size: rem-calc(15);
        font-weight: $global-weight-bold;
      }

      &.high {
        a {
          font-weight: $global-weight-bold;
        }
      }

      a {
        display: block;
        color: $black !important;
        font-size: rem-calc(15);
      }

      b {
        font-size: rem-calc(15);
      }
    }
  }
}

@mixin small-di {
  line-height: 1.3;

  @include breakpoint(small only) {
    padding-bottom: 10px;
    margin: 0;
    list-style: none;
    font-size: 14px;

    & > li {
      justify-content: space-between;
      display: flex;

      &:first-child {
        padding-top: 20px;
      }

      &:not(:last-child) {
        border-bottom: 1px solid #cfcfcf;
      }

      padding: 8px 10px;
      align-items: center;

      & > span {
        font-size: 13px;
        color: #606060;
      }
    }
  }
}

@mixin small-mobile {
  @include breakpoint(small only) {
    font-size: 14px;
    padding: 15px 10px;

    li {
      padding: 8px 0;
      line-height: 1.3;
      align-items: baseline;
    }

    &__title {
      padding: $global-padding 0;
      box-sizing: border-box;
      line-height: initial;
    }
  }

  @include breakpoint(small) {
    .column {
      padding: 0;

      ul {
        list-style: none;
        margin: 0;

        li.selected {
          font-weight: $global-weight-bold;
          color: #2e3191;
        }

        li > b {
          color: #2e3191;

          >span {
            font-weight: $global-weight-normal;
          }
        }

        li {
          display: flex;
          justify-content: space-between;

          span {
            font-size: 12px;
            color: #606060;
            margin-left: 25px;
          }
        }
      }
    }
  }
}

@mixin small-obj() {
  @include breakpoint(small only) {
    height: auto;
    margin-top: 0;
    word-break: break-word;

    &::after {
      @include small-h;

      content: '';
      position: relative;
      height: 10px;
      padding: 0 !important;
    }

    &:last-child {
      &::after {
        display: none;
      }
    }

    &__title {
      display: block;
      margin-bottom: 5px;
      padding: 0 15px;
      color: $black;
      font-size: rem-calc(18);
      font-weight: $global-weight-bold;
      line-height: 1.1;

      // max-height: 1.3rem * 1.1 * 2;
      height: auto;

      a {
        color: $black;
      }
    }

    &__date {
      padding: 0 15px 5px;
      font-size: rem-calc(11);
    }

    &__desc {
      padding: 0 15px;
      font-size: rem-calc(14);
      line-height: 1.2;
      max-height: 1rem * 1.2 * 5;
      overflow: hidden;
      box-sizing: border-box;
    }

    &__more,
    &__contacts,
    &__rubs {
      padding: 5px 15px;
    }
  }
}

@mixin small-padding($padding: 0 15px) {
  @include breakpoint(small only) {
    padding: $padding !important;
  }
}

@mixin obj-thumb($width: 180px, $height: 120px, $breakpoint: medium) {
  @include breakpoint($breakpoint) {
    position: relative;
    display: block;
    width: $width;
    height: $height;
    max-width: 100%;
    margin: 0 auto;
    background-color: $white;

    img {
      position: absolute;
      width: auto;
      height: auto;
      max-height: 100%;
      max-width: 100%;
      inset: 0;
      margin: auto;
    }
  }
}

@mixin color-mixin($color) {
  color: $color;

  &:hover,
  &:focus,
  &:visited {
    color: $color;
  }
}

;@include ---eyygoxs6v1.load-css("sass-embedded-legacy-load-done:3666");