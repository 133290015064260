@use "sass:meta" as ---qyzyywc1vw;.market-empty {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 4rem;
  margin-top: 6rem;
  margin-bottom: 6rem;

  @include breakpoint(small only) {
    gap: 1rem;
  }

  @include breakpoint(medium down) {
    margin: 5rem 0;
  }

  &::before {
    content: '';
    display: block;
    width: 180px;
    height: 180px;
    background-image: url('./svg/icon-attention.svg');
    background-size: contain;
    background-repeat: no-repeat;

    @include breakpoint(small only) {
      width: 130px;
      height: 130px;
    }
  }

  &__message {
    h2 {
      font-size: rem-calc(30);
      font-weight: $global-weight-bold;

      @include breakpoint(small only) {
        font-size: rem-calc(28);
      }
    }

    p {
      @include breakpoint(small only) {
        font-size: rem-calc(14);
      }
    }
  }
}

;@include ---qyzyywc1vw.load-css("sass-embedded-legacy-load-done:3730");