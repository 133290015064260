@use "sass:meta" as ---3m2zn08l3uw;.market-subcategories {
  margin: .6rem 0 1.4rem;

  .items {
    display: flex;
    gap: 2rem;
  }

  .column {
    @include breakpoint(large) {
      margin-bottom: 1rem;
    }
  }

  .item {
    border: 1px solid $light-gray;
    border-radius: $global-radius;
    min-height: 80px;
    padding: 8px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: relative;
    cursor: pointer;
    transition: all .25s ease-out;
    color: $black;

    @include breakpoint(medium down) {
      margin-bottom: 1rem;
    }

    &:hover {
      background-color: $alice-blue;
    }

    .wrapper {
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: .6rem;
    }

    .title {
      font-size: rem-calc(14);
      line-height: 1.3;
      z-index: 2;
      width: 76%;
      overflow: hidden;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 3;
      line-clamp: 3;

      @include breakpoint(small only) {
        font-size: rem-calc(12);
      }
    }

    .img {
      width: 50px;
    }
  }
}

;@include ---3m2zn08l3uw.load-css("sass-embedded-legacy-load-done:3770");