@use "sass:meta" as ---eyygoxs6v1;@use 'sass:math';

.product-card {
  margin-bottom: 2rem;

  h2, h3 {
    background-color: initial;
    border: initial;
    padding: initial;
  }

  h2 {
    font-size: rem-calc(24);
    font-weight: $global-weight-bold;
    position: relative;
    margin-bottom: 1.5rem;

    &::before {
      content: '';
      position: absolute;
      height: 6px;
      top: 36px;
      width: 50px;
      background-color: $light-gray;
    }
  }

  &__info {
    margin-bottom: 2rem;
    margin-top: 2rem;
    align-items: center;

    h3 {
      font-size: rem-calc(18);
      font-weight: $global-weight-bold;
      position: relative;
      margin-bottom: 1.5rem;

      &::before {
        content: '';
        position: absolute;
        height: 6px;
        top: 30px;
        width: 50px;
        background-color: $light-gray;
      }
    }

    .pictures {
      text-align: center;

      img {
        border-radius: $global-radius;
      }
    }

    .properties {
      @include breakpoint(small only) {
        margin-top: 1.5rem;
      }

      .property-list {
        list-style: none;
        margin-left: 0;
        font-size: rem-calc(15);
        line-height: 26px;

        @include breakpoint(large) {
          margin-right: 2rem;
        }

        li {
          display: flex;
          justify-content: space-between;

          .property {
            position: relative;
            display: inline-flex;
            flex-grow: 1;
            align-self: flex-end;

            &::before {
              content: '';
              position: absolute;
              left: 0;
              bottom: 6px;
              width: 100%;
              border-bottom: 1px dotted #e4e4e4;
            }

            .title {
              position: relative;
              padding-right: 4px;
              background: #fff;
              color: #666;
            }
          }

          .value {
            &.brand {
              svg {
                height: 35px;
              }
            }
          }
        }
      }
    }

    .rubrica {
      margin-top: .5rem;
    }
  }

  .features,
  .description {
    display: block;
  }

  .features,
  .description,
  .designfeatures,
  .files {
    margin-top: 2rem;
    margin-bottom: 2rem;
  }

  .features {
    font-size: rem-calc(15);

    .property {
      align-items: flex-end;

      &.gold {
        font-weight: $global-weight-bolder;
      }

      .column {
        margin: 4px 0;
        line-height: 1.3;

        .item {
          position: relative;
          display: inline-flex;
          flex-grow: 1;
          align-self: flex-end;
          width: 100%;

          &::before {
            content: '';
            position: absolute;
            left: 0;
            bottom: 6px;
            width: 100%;
            border-bottom: 1px dotted #e4e4e4;
          }

          .title {
            position: relative;
            padding-right: 4px;
            background: #fff;
            color: #666;
          }
        }
      }
    }
  }

  .designfeatures {
    @include breakpoint(small only) {
      font-size: rem-calc(15);
    }

    .row {
      align-items: center;

      .item {
        display: flex;
        align-items: center;
        margin: 1rem 0;

        figure {
          flex: 0 0 150px;
          display: flex;
          justify-content: center;

          img {
            object-fit: none;
          }
        }

        p {
          margin: 0 0 0 1rem;
          line-height: 1.4;
        }
      }
    }
  }

  .files {
    .files {
      align-items: stretch;

      &__item {
        margin: 8px 0;

        .documents-item {
          margin: 0;
          border-radius: $global-radius;
          height: 100%;
          display: flex;
          flex-direction: column;
          justify-content: center;
          position: relative;
          box-shadow: 0 0 3px rgb(0 0 0 / 12%), 0 2px 4px rgb(0 0 0 / 12%);

          &:hover {
            cursor: pointer;
          }

          &__icon {
            top: auto;
            left: auto;
          }

          &__title {
            font-size: rem-calc(15);
            line-height: 1.4;
          }

          &__info {
            line-height: 1.4;
          }
        }
      }
    }
  }

  .offers-list {
    margin-top: 2rem;

    .items {
      border: 1px solid $alice-blue;
      padding: .6rem;
      border-radius: $global-radius;

      &-wrapper {
        &:not(:first-child) {
          &::before {
            content: '';
            display: block;
            border-top: 1px solid $light-gray;
            margin-bottom: 1rem;
            margin-top: 1rem;
          }
        }

        .item {
          display: flex;
          justify-content: space-between;
          align-items: center;
          gap: 1rem;
          flex-wrap: wrap;

          .seller {
            flex-basis: 34%;

            @include breakpoint(small only) {
              flex-basis: 100%;
            }

            .distributor {
              color: $dark-gray;
              font-size: rem-calc(13);
              font-weight: $global-weight-bold;
              display: flex;
              align-items: center;
              gap: .2rem;

              &::before {
                content: '';
                display: block;
                width: 12px;
                height: 12px;
                background-image: url('svg/icon-green.svg');
                background-repeat: no-repeat;
                background-size: contain;
              }
            }

            .name {
              font-weight: $global-weight-bold;
              display: flex;
              align-items: center;
            }

            .headquarters {
              font-size: rem-calc(14);
              color: $dark-gray;

              @include breakpoint(small only) {
                font-size: rem-calc(12);
              }
            }
          }

          .warehouse {
            flex-basis: 20%;
            font-size: rem-calc(14);
            color: $dark-gray;

            .in-stock {
              display: flex;
              justify-content: space-between;
              color: $buy;

              .quantity {
                color: $dark-gray;
                font-size: rem-calc(14);

                @include breakpoint(small only) {
                  display: none;
                }
              }
            }

            .in-request {
              color: $vacancy;
            }

            ul {
              margin-bottom: 0;
            }

            li {
              display: flex;
              justify-content: space-between;
            }

          }

          .price {
            flex-basis: 20%;
            text-align: center;

            @include breakpoint(small only) {
              flex-grow: 1;
            }

            &.value {
              font-size: rem-calc(18);
              font-weight: $global-weight-bold;

              @include breakpoint(small only) {
                font-size: rem-calc(16);
              }
            }

            &.request {
              font-size: rem-calc(14);
              color: $dark-gray;
            }
          }

          .action {
            flex-basis: 18%;
            display: flex;
            flex-direction: column;
            gap: 1rem;

            @include breakpoint(small only) {
              flex-basis: 34%;
            }

            a {
              width: 100%;
              margin: 0;
            }

            .action_order {
              background-color: $tangerine-yellow;
            }

            .action_request {
              background-color: $alice-blue;
            }

            .on-site {
              display: flex;
              align-items: center;
              justify-content: center;
              gap: .3rem;
              background-color: $alice-blue;

              &::after {
                content: '';
                display: inline-block;
                background-image: url('../../../styles/common/svg/on-site.svg');
                background-size: contain;
                background-repeat: no-repeat;
                width: 12px;
                height: 12px;
                opacity: .5;
              }
            }
          }
        }
      }
    }

    &.empty {
      background-color: $alice-blue;
      border-radius: $global-radius;
      padding: 1.4rem;
      display: flex;
      gap: 3rem;
      align-items: center;

      @include breakpoint(small only) {
        gap: 1.6rem;
      }

      &::before {
        content: '';
        background-image: url('svg/icon-attention.svg');
        background-repeat: no-repeat;
        display: block;
        width: 100px;
        height: 100px;
        opacity: 0.4;
        filter: grayscale(1);
        flex-shrink: 0;
      }

      .notice {
        .title {
          font-size: rem-calc(18);
          font-weight: bold;
          color: $dark-gray;
          line-height: 1.3;
          display: block;
          margin-bottom: 6px;

          @include breakpoint(medium only) {
            font-size: rem-calc(24);
          }

          @include breakpoint(large) {
            font-size: rem-calc(26);
          }
        }

        .text {
          font-size: rem-calc(14);
        }

        ul {
          display: flex;
          margin-top: 10px;
          margin-bottom: 0;
          font-weight: $global-weight-bold;
          gap: 2rem;
        }
      }
    }
  }
}

;@include ---eyygoxs6v1.load-css("sass-embedded-legacy-load-done:3756");